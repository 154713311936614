import PropTypes from 'prop-types';
import React from 'react';

import Link from 'components/links/Link';

function CardBase({ children, link, styles, prefetch = true }) {
    const prefetchProp = !prefetch ? { prefetch: false } : {};
    let cardStyles = 'bg-white rounded-xl shadow-md overflow-hidden';

    if (link) {
        cardStyles += ' hover:shadow-lg transition focus-within:shadow-lg focus-within:ring-2 focus-within:ring-brand';
    }

    if (styles) {
        cardStyles += ' ' + styles;
    }

    return (
        <div className={cardStyles}>
            {link ? (
                <Link to={link} {...prefetchProp}>
                    {children}
                </Link>
            ) : (
                children
            )}
        </div>
    );
}

CardBase.propTypes = {
    children: PropTypes.any,
    link: PropTypes.string,
    styles: PropTypes.string
};

export default CardBase;
