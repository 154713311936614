const locale = 'sl-sl';
export const formatDistance = (meters) => {
    let value = meters;
    let unit = 'm';
    let decimals = 0;
    if (meters > 1000) {
        value = meters / 1000;
        unit = 'km';
        decimals = 1;
    }

    return `${Number(value.toFixed(decimals)).toLocaleString(locale)} ${unit}`;
};

export const formatDuration = (minutes) => {
    const hours = minutes / 60;
    const roundedHours = Math.floor(hours);
    const remainMinutes = (hours - roundedHours) * 60;
    const roundedMinutes = Math.round(remainMinutes);

    if (roundedHours > 0) return `${roundedHours} h ${roundedMinutes > 0 ? `${roundedMinutes} min` : ''}`;
    return `${roundedMinutes} min`;
};

export const formatByteSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
