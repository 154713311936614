import React from 'react';

import PoiAttributesList from 'components/pois/PoiAttributesList';
import PoiAttribute from 'models/PoiAttribute';
import keyCategoriesAttributesMap from 'components/cards/poi/keyCategoriesAttributesMap';
import Category from 'models/Category';

const KeyPoiAttributes = ({ category, attributes = [] }: { category: Category; attributes: PoiAttribute[] }) => {
    const keyAttributesSlugs = keyCategoriesAttributesMap[category.slug];
    if (!keyAttributesSlugs) return null;
    const keyAttributes = attributes.filter((attr) => keyAttributesSlugs.includes(attr.attribute.slug));
    return <PoiAttributesList attributes={keyAttributes} />;
};

export default KeyPoiAttributes;
