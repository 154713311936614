import axios from 'data/axios';
import Area from 'models/Area';
import Poi from 'models/Poi';
import { ApiListResponse, ApiSingleResponse } from 'data/types';
import Comment from 'models/Comment';
import Destination from 'models/Destination';
import Category from 'models/Category';
import PoiMedia from 'models/PoiMedia';

interface ApiFilter {
    key: string;
    value: string;
}

const buildFiltersQuery = (filters: ApiFilter[]): string => {
    // Input example: [{key: "id", value: "1"}, {key: "id", value: "2"}]
    // Output example: "filter[id]=1,2"
    const params = new URLSearchParams();
    filters.forEach((filter) => {
        const key = `filter[${filter.key}]`;
        if (params.has(key)) {
            params.set(key, `${params.get(key)},${filter.value}`);
        } else {
            params.append(key, filter.value);
        }
    });
    return params.toString();
};

const buildIncludesQuery = (includes: string[]): string => {
    // Input example: ["include1", "include2"]
    // Output example: "include=include1,include2"
    return includes.length > 0 ? `include=${includes.join(',')}` : '';
};

export const fetchAreas = (perPage?: number): Promise<ApiListResponse<Area>> => {
    return axios.get(`public/areas?page[size]=${perPage || 9999}`).then(({ data }) => data);
};

export const fetchDefaultAvatars = (): Promise<ApiListResponse<{ url: string }>> => {
    return axios.get(`public/default-avatars`).then(({ data }) => data);
};

export const fetchUserPoiMedia = (): Promise<ApiListResponse<Poi>> => {
    return axios.get(`me/poi-media`).then(({ data }) => data);
};

export const fetchUserContestMedia = (): Promise<ApiListResponse<PoiMedia>> => {
    return axios.get(`me/contest-poi-media`).then(({ data }) => data);
};

export const fetchCategories = ({
    filters = [],
    includes = []
}: {
    filters?: ApiFilter[];
    includes?: string[];
}): Promise<ApiListResponse<Category>> => {
    const filtersQuery = buildFiltersQuery(filters);
    const includesQuery = buildIncludesQuery(includes);
    return axios
        .get(`public/categories?${filtersQuery}${includes ? `&${includesQuery}` : ''}`)
        .then(({ data }) => data);
};

export const fetchPois = ({
    filters = [],
    includes = []
}: {
    filters?: ApiFilter[];
    includes?: string[];
}): Promise<ApiListResponse<Poi>> => {
    const filtersQuery = buildFiltersQuery(filters);
    const includesQuery = buildIncludesQuery(includes);
    return axios.get(`public/pois?${filtersQuery}${includes ? `&${includesQuery}` : ''}`).then(({ data }) => data);
};

export const fetchPoiBySlug = (slug): Promise<ApiSingleResponse<Poi>> => {
    return axios.get(`public/pois/single/${slug}`).then(({ data }) => data);
};

export const fetchPoiById = (id): Promise<ApiSingleResponse<Poi>> => {
    return axios.get(`public/pois/${id}`).then(({ data }) => data);
};

export const fetchCommentsByPoiId = (poi_id: number | string, page: number): Promise<ApiListResponse<Comment>> => {
    return axios.get(`public/comments?poi_id=${poi_id}&page=${page}`).then(({ data }) => data);
};

export const fetchDestinationBySlug = (slug): Promise<ApiSingleResponse<Destination>> => {
    return axios.get(`public/destinations/${slug}`).then(({ data }) => data);
};
