import PropTypes from 'prop-types';
import React from 'react';

import PoiAttributeValue from 'components/pois/PoiAttributeValue';
import PoiAttribute from 'models/PoiAttribute';

const PoiAttributesList = ({ attributes = [], max = Infinity }: { attributes: PoiAttribute[]; max?: number }) => {
    const attributesToShow = attributes.slice(0, max);
    return (
        <div className="space-y-2">
            {attributesToShow.map((poiAttr, i) => {
                if (poiAttr.value) {
                    return (
                        <div className="flex items-start space-x-1" key={i}>
                            <div className="text-sm font-light w-1/2">{poiAttr.attribute.name}</div>
                            <div className="font-medium w-1/2 text-sm">
                                <PoiAttributeValue poiAttribute={poiAttr} />
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
};

PoiAttributesList.propTypes = {
    attributes: PropTypes.array.isRequired
};

export default PoiAttributesList;
