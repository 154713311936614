import Poi, { PoiThumb } from 'models/Poi';
import Area from 'models/Area';
import { ImageConversions } from 'models/PoiMedia';

export const getPoiAreas = (poi: Poi): { region?: Area; municipality?: Area } => {
    const region = poi.areas?.find((area) => area.type.key === 'region');
    const municipality = poi.areas?.find((area) => area.type.key === 'municipality');

    return { region: region, municipality };
};

export const getResizedThumbUrl = (thumb: PoiThumb, size: keyof ImageConversions = 'thumb') => {
    switch (size) {
        case 'thumb':
            return thumb.url;
        case 'medium':
            return thumb.medium_url;
        case 'large':
            return thumb.large_url;
        default:
            return thumb.url;
    }
};

export const getPoiThumbs = (poi: Poi, size: keyof ImageConversions = 'thumb'): PoiThumb[] | [] => {
    if (!poi.photos || poi.photos.length === 0) return [];

    return poi.photos.map((photo) => {
        return {
            id: photo.id,
            url: photo.files.conversions?.[size]?.url ?? ''
        };
    });
};
