import React from 'react';
import PoiTypeIcon from '../../icons/PoiTypeIcon';
import CardBase from 'components/cards/CardBase';
import KeyPoiAttributes from 'components/cards/poi/KeyPoiAttributes';
import PoiCardMedia from './PoiCardMedia';
import PoiType from 'models/PoiType';
import PoiAttribute from 'models/PoiAttribute';
import Tooltip from 'components/TippyTooltip';
import { PoiThumb } from 'models/Poi';
import Category from 'models/Category';
import { formatDistance } from 'utils/formatting';

interface PoiCardProps {
    title: string;
    images: PoiThumb[];
    link: string;
    location: string;
    poiType: PoiType;
    attributes?: PoiAttribute[];
    category?: Category;
    prefetch?: boolean;
    featured?: boolean;
    distance?: number;
    photoContest?: boolean;
}

function PoiCard({
    title,
    images = [],
    link,
    location,
    poiType,
    attributes = [],
    category,
    prefetch = true,
    distance,
    featured = false,
    photoContest
}: PoiCardProps) {
    return (
        <CardBase styles={`w-full max-w-xs ${featured ? 'border-2 border-brand' : ''}`} prefetch={prefetch} link={link}>
            <div className="h-40 md:h-48 relative">
                <PoiCardMedia images={images} alt={title} className={'rounded-t-xl'} />
                {featured && (
                    <div className="absolute bottom-2 right-2 rounded-full py-1 px-3 bg-white text-primary text-xs z-10">
                        Sponzorirano
                    </div>
                )}
                {photoContest && !featured && (
                    <div className="absolute -bottom-3 right-4 z-20">
                        <img src="/images/foto-izbor/badge.svg" alt="Nagradni foto izbor" className="w-8" />
                    </div>
                )}
            </div>
            <div className="px-4 pb-4 pt-6 relative z-10">
                {poiType && (
                    <div className="absolute -top-10 w-14 h-14 rounded-full shadow-md bg-white flex items-center justify-center">
                        <Tooltip title={poiType.name} position="top" arrow={true} arrowSize="small" animation="fade">
                            <PoiTypeIcon slug={poiType.slug} size={36} />
                        </Tooltip>
                    </div>
                )}

                <h4 className="leading-tight font-medium text-brand font-serif">{title}</h4>
                {location && <p className="font-medium text-sm">{location}</p>}
                {category && (
                    <div className="mt-3">
                        {distance && (
                            <div className="flex items-start space-x-1 mb-2">
                                <div className="text-sm font-light w-1/2">Oddaljenost</div>
                                <div className="font-medium w-1/2 text-sm">{formatDistance(distance)}</div>
                            </div>
                        )}
                        {attributes && <KeyPoiAttributes category={category} attributes={attributes} />}
                    </div>
                )}
            </div>
        </CardBase>
    );
}

export default PoiCard;
