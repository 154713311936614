import React from 'react';

import { formatDuration } from 'utils/formatting';
import AccessIcons from '../icons/AccessIcons';
import ActivityIcons from '../icons/ActivityIcons';
import Link from 'components/links/Link';
import PoiAttribute from 'models/PoiAttribute';

const parseBooleanValue = (value) => {
    if (['1', 1, 'yes', 'da'].includes(value)) {
        return 'Da';
    } else {
        return 'Ne';
    }
};

const PoiAttributeValue = ({ poiAttribute }: { poiAttribute: PoiAttribute }) => {
    const { slug, options, type, unit } = poiAttribute.attribute;
    const value = poiAttribute.value;

    if (slug === 'aktivnosti') return <ActivityIcons activities={value} />;
    if (slug === 'dostopnost') return <AccessIcons modes={value} />;

    let label = value;
    if (options) {
        const option = options.find((opt) => opt.value == value);
        if (option) {
            label = option.label || option.value;
        }
    }

    if (type === 'array') {
        label = value.map((v) => v?.value || v).join(', ');
    }

    if (type === 'boolean') {
        label = parseBooleanValue(value);
    }

    if (unit === 'min') {
        label = formatDuration(value);
    }

    if (slug === 'spletna-stran') {
        label = (
            <Link
                target="_blank"
                styles="inline-block whitespace-nowrap max-w-full overflow-hidden overflow-ellipsis underline"
                to={label}
            >
                {label}
            </Link>
        );
    }

    return (
        <>
            {label}
            {unit && unit !== 'min' && <span className="text-xs font-normal ml-1">{unit}</span>}
        </>
    );
};

export default PoiAttributeValue;
