import { Avto, CestnoKolo, GorskoKolo, Pohodnistvo, Zicnica } from 'kzv-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'react-tippy';

export const accessIconsMap = {
    'Dostopno peš': Pohodnistvo,
    'Dostopno s kolesom': CestnoKolo,
    'Dostopno z gorskim kolesom': GorskoKolo,
    'Dostopno z avtom': Avto,
    'Dostopno z žičnico': Zicnica
};

function AccessIcons({ modes }) {
    return (
        <div className="flex flex-wrap items-center text-2xl space-x-1">
            {modes.map((mode, i) => {
                const modeValue = mode?.value ?? mode;
                if (!accessIconsMap[modeValue]) {
                    return null;
                } else {
                    const Icon = accessIconsMap[modeValue];
                    return (
                        <Tooltip
                            key={i}
                            title={modeValue}
                            position="top"
                            arrow
                            size="small"
                            arrowSize="small"
                            distance={5}
                            animation="fade"
                        >
                            <Icon />
                        </Tooltip>
                    );
                }
            })}
        </div>
    );
}

AccessIcons.propTypes = {
    modes: PropTypes.array.isRequired
};

export default AccessIcons;
