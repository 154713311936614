import {
    Drsanje,
    Kajak,
    Kamping,
    KopanjePsov,
    Piknik,
    Plavanje,
    Ribolov,
    SkakanjeVVodo,
    Sprehod,
    Supanje,
    Zoga
} from 'kzv-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'react-tippy';

export const activityIconsMap = {
    Supanje,
    Kajak,
    Piknik,
    'Športne igre': Zoga,
    Ribolov,
    Plavanje,
    Sprehod,
    'Kopanje psov': KopanjePsov,
    'Drsanje pozimi': Drsanje,
    Camping: Kamping,
    'Skakanje v vodo': SkakanjeVVodo
};

function ActivityIcons({ activities }) {
    return (
        <div className="flex items-center text-2xl space-x-1 flex-wrap">
            {activities.map((activity, i) => {
                const Icon = activityIconsMap[activity];
                if (!Icon) {
                    return null;
                } else {
                    return (
                        <Tooltip
                            key={i}
                            title={activity}
                            position="top"
                            arrow
                            size="small"
                            arrowSize="small"
                            distance={5}
                            animation="fade"
                        >
                            <Icon />
                        </Tooltip>
                    );
                }
            })}
        </div>
    );
}

ActivityIcons.propTypes = {
    activities: PropTypes.array.isRequired
};

export default ActivityIcons;
